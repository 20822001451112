import React,{useState, useEffect} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import catLogo from "../../images/brands/CAT.jpg"
import cummingsPhoto from "../../images/brands/cummings.jpg"
import danfossPhoto from "../../images/brands/danfoss.jpg"
import detroitPhoto from "../../images/brands/detroitdiesel.jpg"
import deutzPhoto from "../../images/brands/DEUTZ.jpg"
import perkinsPhoto from "../../images/brands/PERKINS.jpg"
import volvoPhoto from "../../images/brands/volvo-penta-logo.jpg"
import woodwardPhoto from "../../images/brands/woodward.jpg"

export default function BrandSlider() {
	const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1200);

    const settings = {
        // dots: true,
        infinite: true,
		autoplay: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
		autoplaySpeed: 2500,

      };
    const mobileSettings = {
        // dots: true,
        infinite: true,
		autoplay: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
		autoplaySpeed: 100,

      };

      useEffect(() => {
		// An event listener to update window size when it changes
		window.addEventListener('resize', updateWindowSize);
	
		// Clean up the event listener when the component unmounts
		return () => {
		  window.removeEventListener('resize', updateWindowSize);
		};
	  }, []);

      const updateWindowSize = () => {
		setIsDesktop(window.innerWidth > 1200); 
	  };

    return (
       <div className="mt-4">
        <div className="text-center mt-4 pb-4">
            <h2><b>Brands We Work With</b> </h2>
        </div>
       {isDesktop && ( <Slider {...settings} >
       
            <div >
					<img src={catLogo} height="100px;" width="180px" />
            </div>
            <div >
					<img src={cummingsPhoto} height="100px;" width="180px"/>
            </div>
            <div>
					<img src={danfossPhoto} height="100px;" width="180px" />
            </div>
            <div>
					<img src={detroitPhoto} height="100px;" width="180px"/>
            </div>
            <div>
					<img src={deutzPhoto} height="100px;" width="180px"/>
            </div>
            <div>
					<img src={perkinsPhoto} height="100px;" width="180px"/>
            </div>
            <div>
					<img src={volvoPhoto} height="100px;" width="180px"/>
            </div>
            <div>
					<img src={woodwardPhoto} height="100px;" width="180px" />
            </div>
        </Slider>)}

        {!isDesktop &&
        (<Slider {...mobileSettings} >
       
            <div >
					<img src={catLogo} height="100px;" width="180px" />
            </div>
            <gap />
            <div>
					<img src={cummingsPhoto} height="100px;" width="180px"/>
            </div>
            <gap />
            <div>
					<img src={danfossPhoto} height="100px;" width="180px" />
            </div>
            <gap />

            <div>
					<img src={detroitPhoto} height="100px;" width="180px"/>
            </div>
            <gap />

            <div>
					<img src={deutzPhoto} height="100px;" width="180px"/>
            </div>
            <gap />

            <div>
					<img src={perkinsPhoto} height="100px;" width="180px"/>
            </div>
            <gap />

            <div>
					<img src={volvoPhoto} height="100px;" width="180px"/>
            </div>
            <gap />

            <div>
					<img src={woodwardPhoto} height="100px;" width="180px" />
            </div>
            <gap />

        </Slider>)}
       </div>
    )
}