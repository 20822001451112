import React from "react";
import { ReactDOM } from "react";
import Slider from "react-slick";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import inventor from "../images/inventor.jpg";

export default function TopPage() {
	return (
		<div className="vh-100 d-flex align-items-center justify-content-center">
      <div className="container pt-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="col-lg-6 p-2">
            <div className="">
              <h1 className="text-white overlay-title">Swift Fix Services</h1>
              <div className="card-text pt-4">
                <p>
                  We are an engineering, procurement, and consultancy company.
                  With our experience, value creation, and track record of excellence,
                  Swift Fix Services is proud to consistently provide benchmarked services
                  to all of our clients.
                </p>
              </div>
              <button className="btn-style">
                <span>About Us</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
			
	);
}
{/* <div className="container  pt-4">
				<div className="d-flex align-items-center justify-content-between ">
					
					<div className="col-lg-6 p-2">
						<div className="">
							<h1 className="text-white">Swift Fix Services</h1>
							<div className="card-text">
								<p>
									We are an engineering, procurement and consultancy company.

									With our experience, value creation
								and track record of excellence
								Swift Fix Services is proud to
								consistently provides benchmarked
								services to all of our clients..
								</p>
							</div>
							<button href="#" className="btn-style">
								<span>About Us</span>
							</button>
						</div>
					</div>
					
				</div>
			</div> */}