import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import gears from "../../images/gears.jpg";
import { motion } from "framer-motion/dist/framer-motion";
import fixing from "../../images/fixing.jpeg";
import inventor from "../../images/inventor.jpg";

export default function WhoAreWe() {
	const [redirectUrl, setRedirectUrl] = useState(null);

	const redirectToAboutUs = () => {
		setRedirectUrl("/about-us");
	};
	return redirectUrl ? (
		<Navigate to={redirectUrl} replace={true} />
	) : (
		<main>
			<div className="container">
				<div className="row align-items-center justify-content-between  gx-4">
					<div className="col col-md-6">
						<div className="card border-white shadow-none">
							<div className="card-body ">
								<br />
								<h4 className="card-title whoWeAreTitle">
									<b>WHO WE ARE</b>
								</h4>
								<br />
								<p className="card-text whoWeAreText">
									Swift Fix Services is a Nigerian company with globally
									recognized expertise in providing high-quality engineering
									services. SFS provides comprehensive, dependable, and
									cost-effective consulting, repair and procurement solutions in
									Nigeria and throughout West Africa, backed by an exemplary
									safety and project management track record and a seasoned team
									of experts.
								</p>
								<br />

								<motion.button
									onClick={() => redirectToAboutUs()}
									className="btn btn-lg btn-dark"
									whileHover={{ scale: 1.1 }}
									whileTap={{ scale: 0.9 }}>
									Read More
								</motion.button>
								<br />
								<br />
							</div>
						</div>
					</div>
					<div className="col-md-6 d-none d-md-block">
						<div className="justify-content-center align-items-center">
							<div className="w-90 bg-light">
								<img src={inventor} width="100%" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}
