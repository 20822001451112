import React, { useState, useEffect } from "react";
import logo from "../../images/round-logo.svg";
import "./navBar.css";
import { Link } from "react-router-dom";
import { icon } from "@fortawesome/fontawesome-svg-core";

export default function NavBar() {
	const [toggleMenu, setToggleMenu] = useState(false);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	const toggleNav = () => {
		const toggleButton = document.getElementsByClassName("toggle-button")[0];
		const navbarLinks = document.getElementsByClassName("navbar-links")[0];
		toggleButton.addEventListener("click", () => {
			navbarLinks.classList.toggle("active");
		});
	};
	icon({ name: "user", family: "classic", style: "solid" });

	useEffect(() => {
		const changeWidth = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener("resize", changeWidth);
		return () => {
			window.removeEventListener("resize", changeWidth);
		};
	}, []);

	window.onscroll = () => {
		scrollNavbar();
	};

	//implementing colour change on scroll
	const scrollNavbar = () => {
		// Target elements
		const navBar = document.getElementById("navBarClass");
		const links = document.querySelectorAll("#navBarClass a");

		if (document.documentElement.scrollTop > 100) {
			navBar.classList.add("pa-fixed-header");

			// Change the color of links on scroll
			for (let i = 0; i < links.length; i++) {
				const element = links[i];
				element.classList.add("text-black");
			}
		} else {
			navBar.classList.remove("pa-fixed-header");

			// Change the color of links back to default
			for (let i = 0; i < links.length; i++) {
				const element = links[i];
				element.classList.remove("text-black");
			}
		}
	};

	return (
		<nav className="navbar  item-adjustment shadow-lg" id="navBarClass">
			<div className="brand-logo">
				<img classNameName="brand-logo ml-2" src={logo} height="65px"></img>
			</div>
			<a href="#" className="toggle-button" onClick={toggleNav}>
				<span className="bar"></span>
				<span className="bar"></span>
				<span className="bar"></span>
			</a>
			<div className="navbar-links">
				<ul>
					<li>
						<Link to="/">
							<i className="fa-solid fa-house "></i>
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/about-us">About Us</Link>
					</li>
					<li>
						<Link to="/our-services">Our Services</Link>
					</li>
					<li className="">
						<Link to="/contact-us">Contact Us</Link>
					</li>
				</ul>
			</div>
		</nav>
	);
}
