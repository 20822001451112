import React from "react";
import NavBar from "../components/navBar/NavBar";
import Footer from "../components/footer/footerComponent";
import collage from "../images/collage.jpg";

export default function AboutUsPage() {
	return (
		<div>
			<>
				<NavBar />
			</>
			<div className="container mt-5">
				<div className="row">
					<div className="">
						<div className="text-center">
						<p className="pageTitle ">About Us</p>
						<hr
									className="mb-4 mt-0 d-inline-block mx-auto hr-style"
								/>
						</div>
						<p>
							<img
								src={collage}
								height="500px;"
								className="card-img-top"
								alt="..."
							/>
						</p>
						<p>
							With experience and a track record of excellence and value
							creation, Swift Fix Services is proud to consistently provides
							benchmarked service to all of our clients.
						</p>

						<div>
							<h2><b>WHO WE ARE</b></h2>
							<p>
								Swift Fix Services is a Nigerian company with globally
								recognized expertise in providing high-quality engineering
								services. SFS provides comprehensive, dependable, and
								cost-effective consulting, repair and procurement solutions in Nigeria
								and throughout West Africa, backed by an exemplary safety and
								project management track record and a seasoned team of experts.
							</p>

							<p>
								We have built a stellar reputation and strong relationships over
								the years, which are testaments to our track record, reputation,
								and the strength of our technical team.
							</p>
						</div>

						<div>
							<h2><b>Mission</b></h2>
							<p>
								Swift Fix Services provides sustainable engineering solutions,
								products, and services to construction, energy, offshore, and
								industrial sectors.
							</p>
						</div>

						<div>
							<h2><b>Vision</b></h2>
							<p>
								To be the partner of choice for construction, energy, offshore,
								and manufacturing industries.
							</p>
						</div>

						<div>
							<h2><b>Our Core Values</b></h2>
							<ul>
								<li>
									<strong>Customer Satisfaction:</strong> Customers’ needs are
									fundamental to our success. To provide the best options in the
									choice of products and services at affordable rates and fast
									delivery time.
								</li>
								<li>
									<strong>Quality:</strong> Achieve quality standards in
									fulfilling our customers’ requirements and satisfaction.
								</li>
								<li>
									<strong>Community Responsibility:</strong> We view our
									business as an integral part of society. Committed to being a
									responsible corporate body globally and locally and giving
									back to the society.
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<>
				<Footer />
			</>
		</div>
	);
}
