import React, { useRef } from "react";
import { Link } from "react-router-dom";
import {
	motion,
	useScroll,
	useSpring,
	useTransform,
} from "framer-motion/dist/framer-motion";
import engineeringPhoto from "../../images/engineering1.jpg";
import logisticsPhoto from "../../images/logistics.jpg";
import consultingPhoto from "../../images/consulting.jpg";
import hvacPhoto from "../../images/hvac3.jpg";
import electricalPhoto from "../../images/Electrical-Electronics-Engineer.jpg";
import weldingPhoto from "../../images/welding_fabrication.jpg"

export default function companyValues() {
	return (
		<div className="">
			<div className="text-center pt-3 pb-3 ">
				<h2 className="pageTitle"><b>Our Services</b></h2>
				<div className="ourServiceContainer">
					{/* <p className="">Backed by years of experience, we bring this strong mooring services expertise 
                    to bear for our clients, who include many multinational and
                     indigenous oil and gas companies for whom safety and reliability are paramount.</p> */}
				</div>
			</div>
		
			{/* <br/>
            <br/>
            <br/> */}

			<div className="card-group ">
				<div className="card m-4 border-light shadow-lg cardStyle">
                    <div className="cardStyle-img-wrapper">
					<img
						src={engineeringPhoto}
						height="250px;"
						className="card-img-top"
						alt="..."
					/>
					<ul className="list-inline-item text-center member-icons animate">
						<li className="list-inline-item">
							<Link to="/our-services">
							<button className="view-button" >
								<i className="fa-regular fa-eye"></i> View more
							</button>
							</Link>
						</li>
					</ul>
                    </div>
					<div className="card-body">
						<h5 className="card-title text-center">
							<b>Engineering</b>
						</h5>
					</div>
				</div>
				<div className="card m-4 border-light shadow-lg cardStyle">
                    <div className="cardStyle-img-wrapper">
					<img
						src={hvacPhoto}
						height="250px;"
						className="card-img-top"
						alt="..."
					/>
					<ul className="list-inline-item text-center member-icons animate">
						<li className="list-inline-item">
						<Link to="/our-services">
							<button className="view-button">
								<i className="fa-regular fa-eye"></i> View more
							</button>
							</Link>
						</li>
					</ul>
                    </div>
					<div className="card-body">
						<h5 className="card-title text-center">
							<b>HVAC</b>
						</h5>
					</div>
				</div>
				<div className="card m-4 border-light shadow-lg cardStyle">
                    <div className="cardStyle-img-wrapper">
					<img
						src={logisticsPhoto}
						height="250px;"
						className="card-img-top"
						alt="..."
					/>
					<ul className="list-inline-item text-center member-icons animate">
						<li className="list-inline-item">
						<Link to="/our-services">
							<button className="view-button">
								<i className="fa-regular fa-eye"></i> View more
							</button>
							</Link>
						</li>
					</ul>
                    </div>
					<div className="card-body">
						<h5 className="card-title text-center">
							<b>Logistics & Equipment</b>
						</h5>
					</div>
				</div>
			</div>
			<div className="card-group">
				<div className="card m-4 border-light shadow-lg cardStyle">
                    <div className="cardStyle-img-wrapper">
					<img
						src={consultingPhoto}
						height="250px;"
						className="card-img-top"
						alt="..."
					/>
					<ul className="list-inline-item text-center member-icons animate">
						<li className="list-inline-item">
						<Link to="/our-services">
							<button className="view-button">
								<i className="fa-regular fa-eye"></i> View more
							</button>
							</Link>
						</li>
					</ul>
                    </div>
					<div className="card-body text-center">
						<h5 className="card-title">
							<b>Consultancy</b>
						</h5>
					</div>
				</div>
				<div className="card m-4 border-light shadow-lg cardStyle">
                    <div className="cardStyle-img-wrapper">
					<img
						src={electricalPhoto}
						height="250px;"
						className="card-img-top"
						alt="..."
					/>
					<ul className="list-inline-item text-center member-icons animate">
						<li className="list-inline-item">
						<Link to="/our-services">
							<button className="view-button">
								<i className="fa-regular fa-eye"></i> View more
							</button>
							</Link>
						</li>
					</ul>
                    </div>
					<div className="card-body text-center">
						<h5 className="card-title">
							<b>Electrical Electronics Automation</b>
						</h5>
					</div>
				</div>
				
				<div className="card m-4 border-light shadow-lg cardStyle">
                    <div className="cardStyle-img-wrapper">
					<img
						src={weldingPhoto}
						height="250px;"
						className="card-img-top"
						alt="..."
					/>
					<ul className="list-inline-item text-center member-icons animate">
						<li className="list-inline-item">
						<Link to="/our-services">
							<button className="view-button">
								<i className="fa-regular fa-eye"></i> View more
							</button>
							</Link>
						</li>
					</ul>
                    </div>
					<div className="card-body">
						<h5 className="card-title text-center">
							<b>Welding & Fabrication</b>
						</h5>
					</div>
				</div>
			</div>
		</div>
	);
}
