import React from "react";
import Footer from "../components/footer/footerComponent";
import NavBar from "../components/navBar/NavBar";
import bot from "../images/bot.jpeg";
import gears from "../images/gears.jpg";
import "../App.css";

import ImageSlider from "../components/sliderComponent/ImageSlider";
import WhoAreWe from "../components/whoWeAre/whoWeAre";
import CoreValues from "../components/companyValues/companyCoreValues"
import ServicesPage from "../components/ourServices/ourServicesHome";
import BrandSlider from "../components/sliderComponent/brandSlider";
import PageFooter from "../components/footer/footerComponent";
import TopPage from "./topPage";
import WhyChooseUsPage from "../components/whyChooseUs/whyChooseUs";
export default function Homepage() {
	return (
		<div className="">
			<div className="stickyThead">
				<NavBar />
			</div>
			<div className="page-top-background">
				{/* <ImageSlider /> */}
				<TopPage />
			</div>
			<div className="pt-4 pb-4">
				<WhoAreWe />
			</div>
			<div className="pt-4">
				<WhyChooseUsPage />
			</div><br/>
            <br/>
			<div className="pb-4">
				<CoreValues />
			</div><br/>
            <br/>
			<div>
				<ServicesPage className="vh-100"/>
			</div>
			<div>
				<BrandSlider />
			</div>
			<div>
				<PageFooter />
			</div>
		</div>
	);
}
