import React, { useState } from "react";
import NavBar from "../components/navBar/NavBar";
import Footer from "../components/footer/footerComponent";
import payloadUpdater from "../utility/payloadUpdater";
import { Validator, ValidatorGroup } from "../utility/validators";
import Spinner from "../utility/spinner";
import CustomModal from "../utility/customModal";
import { EmailJSResponseStatus } from "emailjs-com";
// import emailjs from "@emailjs/browser";

export default function ContactUsPage() {
	const [payload, setPayload] = useState([]);
	const [loading, setLoading] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [modalTitle, setModalTitle] = useState("Information");
	const [modalContent, setModalContent] = useState("Please fill");
	const [displayWarning, setDisplayWarning] = useState(false);

	const contactForm = new payloadUpdater({
		payload: payload,
		setPayload: setPayload,
	}).update;

	const submitForm = () => {
		console.log("payload: ", payload);
		if (!payload.contactUsFullName || !payload.email || !payload.userMessage) {
			setShowErrorModal(true);
			setModalTitle("Error!");
			setModalContent("We just encountered a network error, kindly try again");
			return;
		} else {
			return
			// const sendEmail =  emailjs.sendForm(
			// 	"YOUR_SERVICE_ID",
			// 	"YOUR_TEMPLATE_ID",
			// 	// form.current,
			// 	"YOUR_PUBLIC_KEY"
			// )
		
		}
	};
	// setLoading(false);
	// 	setShowErrorModal(true);
	// 	setModalTitle("Error!");
	// 	setModalContent("We just encountered a network error, kindly try again");

	return loading ? (
		<div className="text-center mt-5">
			<Spinner />
		</div>
	) : (
		<div>
			<>
				<NavBar />
			</>
			<div>
				<div className="container mt-5 pt-3">
					<div className="row justify-content-center">
						<div className="col-sm-6">
							<form className="m-2 p-2">
								<div className="pb-3">
									<h1>Start a conversation with us.</h1>
									<hr className="text-center mt-0" />
								</div>
								<div className="form-group">
									<label name="firstName">Full Name:</label>
									<input
										type="text"
										className="form-control"
										id="firstName"
										onChange={(e) =>
											contactForm("contactUsFullName", e.target.value)
										}
										placeholder="Enter your full name"
									/>
								</div>
								<br />
								<div className="form-group">
									<label name="email">Email address:</label>
									<input
										type="email"
										className="form-control"
										id="email"
										placeholder="Enter your email"
										onChange={(e) => contactForm("email", e.target.value)}
									/>
								</div>
								<br />
								<div className="form-group">
									<label name="phone-number">Phone Number:</label>
									<input
										type="number"
										className="form-control"
										id="password"
										placeholder="Enter your phone number"
										onChange={(e) => contactForm("phoneNumber", e.target.value)}
									/>
								</div>
								<br />
								<div className="form-group">
									<label name="phone-number">Leave us a message:</label>
									<textarea
										type="text"
										className="form-control"
										id="password"
										placeholder="Please leave us a comment"
										onChange={(e) => contactForm("userMessage", e.target.value)}
									/>
								</div>
								<br />
								<button
									type="button"
									className="view-button mt-3 mb-3"
									onClick={() => submitForm()}>
									Submit
								</button>
							</form>
						</div>
						<div className="col-md-4 ml-4 border-black">
							<div className="p-3 text-center">
								<h1 className="pt-2">Meet us</h1>
								<hr className="mb-4 mt-0 d-inline-block mx-auto hr-style" />
								<p>
									<i className="fas fa-phone mr-3" />
									+234 813 259 5801
								</p>
								<p>
									<i className="fas fa-location-dot mr-3" /> 24A, Awkuzu Street,
									Lekki phase 1, Lagos
								</p>
								<p>
									<i className="fas fa-envelope mr-3" />{" "}
									info@swiftfixservicesltd.com
								</p>
							</div>

							<div
								style={{ height: "400px" }}
								// onMouseOver={() => {
								//     !mapResized && resizeMap();
								// }}
							>
								<iframe
									title="My Google Map"
									width="100%"
									height="400"
									loading="lazy"
									allowFullScreen
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.6775105347124!2d3.461924899999999!3d6.4354514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf5aa5046aaab%3A0x3cab4a3c64a723bd!2s24a%20Awkuzu%20St%2C%20Lekki%20Phase%201%2C%20Lekki%20105102%2C%20Lagos!5e0!3m2!1sen!2sng!4v1702113458345!5m2!1sen!2sng"></iframe>
								{/* <MapContainer
									center={[6.4354514, 3.4619249]}
									zoom={13}
									scrollWheelZoom={false}>
									<TileLayer
										attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
										url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									/>
									<Marker position={[6.4354514, 3.4619249]}>
										<Popup>
											You have found us! <br />
										</Popup>
									</Marker>
								</MapContainer> */}
							</div>
						</div>
					</div>
				</div>
			</div>

			<>
				<Footer />
			</>
		</div>
	);
}
