import React from "react";
import NavBar from "../components/navBar/NavBar";
import Footer from "../components/footer/footerComponent";
import { Collapse } from "react-bootstrap";
import Button from "react-bootstrap";
import { Accordion } from "react-bootstrap";

export default function ourServicesPage() {
	return (
		<div className="services-background">
			<div className="stickyThead ">
				<NavBar />
			</div>
			<div className="container ">
				<div className="row">
					<div className="text-center pt-5">
						<p className="pageTitle">Our Services</p>
						<hr className="mb-4 mt-0 d-inline-block mx-auto hr-style" />
						<p>
							<h5>
								Backed by years of experience, we bring this strong mooring
								services expertise to bear for our clients, who include many
								multinational and indigenous oil and gas companies for whom
								safety and reliability are paramount
							</h5>
						</p>
					</div>
					<div className="pt-2">
						<div className="row">
							<div className="col-md-6">
								<Accordion defaultActiveKey="0" className="">
									<Accordion.Item eventKey="0" className="my-2 ">
										<Accordion.Header>
											{" "}
											<i className="fa-solid fa-gears fa-bounce"></i>{" "}
											&nbsp;&nbsp;&nbsp;<b> DIESEL ENGINES</b>
										</Accordion.Header>
										<Accordion.Body>
											<li> Sales</li>
											<li> Installation</li>
											<li> Troubleshoot</li>
											<li> Repair</li>
											<li>Maintenance</li>
											<li> Repair/Overhaul of Turbocharger</li>
											<li> Repair of Governor/Actuator</li>
											<li> Repair of Fuel Injectors</li>
											<p className="pt-3">
												<h6>
													We offer the above for Different Brands of engines
													such as: &nbsp;
													<span className="brand-style">
														<b>
															MAK, Wartisla, Duetz, Detroit, Perkins, NIIGATA,
															Cummins, CAT etc
														</b>
													</span>
												</h6>
											</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1" className="my-2">
										<Accordion.Header>
											{" "}
											<i className="fa-solid fa-industry fa-bounce"></i>{" "}
											&nbsp;&nbsp;&nbsp;<b> INDUSTRIAL ENGINES & GENERATOR</b>
										</Accordion.Header>
										<Accordion.Body>
											<li>Repair</li>
											<li>Services/Maintenance</li>
											<li>Sales</li>
											<li>Spare Parts</li>
											<p className="pt-3">
												Brands:&nbsp;{" "}
												<span className="brand-style">
													<b>Cummins, Mikano, JMP, Lion Rock etc</b>
												</span>
											</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2" className="my-2">
										<Accordion.Header>
											{" "}
											<i className="fa-solid fa-gear fa-bounce"></i>{" "}
											&nbsp;&nbsp;&nbsp;<b> INBOARD & OUTBOARD ENGINES</b>
										</Accordion.Header>
										<Accordion.Body>
											<li>Sales</li>
											<li>Accessories</li>
											<li>Installation</li>
											<li>Repair and Services</li>
											<p className="pt-3">
												Brands:&nbsp;{" "}
												<span className="brand-style">
													<b>Yamaha, Suzuki, Mercury, OXE etc.</b>
												</span>
											</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="3" className="my-2">
										<Accordion.Header>
											{" "}
											<i className="fa-solid fa-snowflake fa-bounce"></i>{" "}
											&nbsp;&nbsp;&nbsp;<b> HVAC</b>
										</Accordion.Header>
										<Accordion.Body>
											<li>Sales</li>
											<li>Service</li>
											<li>Installation</li>
											<li>Spare Parts</li>
											<li>Refrigerants: R404, 134A, 401C etc.</li>
											<li>Compressor Oils</li>
											<p className="pt-3">
												Above for Compressor, Condenser-Indoor, Outdoor,
												Evaporators and Cooling units: Copeland, Carrier,
												National, LG, Bitzer, Danfoss, Harp, Atlas Copco, Sauer,
												etc
											</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="4" className="my-2">
										<Accordion.Header>
											{" "}
											<i className="fa-solid fa-plug-circle-bolt fa-bounce"></i>{" "}
											&nbsp;&nbsp;&nbsp;<b> ELECTRICAL & ELECTRONICS</b>
										</Accordion.Header>
										<Accordion.Body>
											<p>
												<b>Electronics :</b>
											</p>
											<li>Sales</li>
											<li>Installation</li>
											<li>Repair</li>
											<li>Services</li>
											<li>Automation</li>
											<li>Process Control</li>
											<p className="pt-2">
												<b>Electricals :</b>
											</p>
											<ul>
												<li>Cables: Sales & Installation</li>
												<li>Electrical Motors: AC & DC:</li>
												<ul>
													<li>Sales</li>
													<li>
														o Rewind of Copper Windings Electric Motor-{" "}
														<span className="brand-style"><b>
															{" "}
															ABB, Siemens, Weg, General Electric. Nidec, Teco,
															Faulhaber, Hoyer, Hitachi,</b>
														</span>{" "}
													</li>
													<li>
														o Rewind and Repair of Alternator (Rotor, Stator,
														Exciter etc.)-
														<span className="brand-style"><b>
															Leroy Somer, Stamford, Marelli, MECC Alte,
															marathon etc
														</b></span>
													</li>
												</ul>
											</ul>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="5" className="my-2">
										<Accordion.Header>
											{" "}
											<i className="fa-solid fa-solar-panel fa-bounce"></i>{" "}
											&nbsp;&nbsp;&nbsp;<b> SOLAR SYSTEM</b>
										</Accordion.Header>
										<Accordion.Body>
											<p>
												Solar Inverters, Solar Batteries, Solar Panels, & Charge
												Controllers, Starter & Backup Battery, UPS
											</p>
											<li>Sales</li>
											<li>Installation</li>
											<li>Maintenance</li>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="6" className="my-2">
										<Accordion.Header>
											{" "}
											<i className="fa-solid fa-lightbulb fa-bounce"></i>{" "}
											&nbsp;&nbsp;&nbsp;<b> PLCs & POWER SUPPLY</b>
										</Accordion.Header>
										<Accordion.Body>
											<li>Sales & Installation</li>
											<li>Control Panel</li>
											<li>Installation</li>
											<li>Troubleshooting</li>
											<li>Design</li>
											<li>Repair</li>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
							<div className="col-md-6">
								<Accordion defaultActiveKey="0">
									<Accordion.Item eventKey="0" className="my-2">
										<Accordion.Header>
											{" "}
											<i className="fa-solid fa-gas-pump fa-bounce"></i>{" "}
											&nbsp;&nbsp;&nbsp;<b> PUMPS</b>
										</Accordion.Header>
										<Accordion.Body>
											<li>Sales</li>
											<li>Installation</li>
											<li>Repair</li>
											<p className="pt-3">
												{" "}
												<span className="brand-style">
													<b>
														Gear, Flow, Centrifugal, Rotary, Hydraulic,
														submersible, Booster etc.
													</b>
												</span>
											</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1" className="my-2">
										<Accordion.Header>
											{" "}
											<i className="fa-solid fa-gears fa-bounce"></i>{" "}
											&nbsp;&nbsp;&nbsp;<b> WELDING</b>
										</Accordion.Header>
										<Accordion.Body>
											<li>Gas</li>
											<li>Pipes</li>
											<li>Steel</li>
											<li>Cast</li>
											<li>Fabrication</li>
											<li>Schematic design and construction</li>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2" className="my-2">
										<Accordion.Header>
											{" "}
											<i className="fa-solid fa-gas-pump fa-bounce"></i>{" "}
											&nbsp;&nbsp;&nbsp;<b> LUBRICANTS</b>
										</Accordion.Header>
										<Accordion.Body>
											<li>Mineral & Synthetic</li>
											<li>DPK, AGO, PMS, ATK</li>
											<li>Repair</li>
											<li>Gear Oil: <span className="brand-style"><b>Mobil, Shell, Total,</b>
											</span>
											</li>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="3" className="my-2">
										<Accordion.Header>
											{" "}
											<i className="fa-solid fa-screwdriver-wrench fa-bounce"></i>{" "}
											&nbsp;&nbsp;&nbsp;<b> CONSTRUCTION & LEASING</b>
										</Accordion.Header>
										<Accordion.Body>
											<li>
												Barges: Dumb, boat haulage, power barge, split hopper
											</li>
											<li>
												Lifting Equipment: Crane, Hoist, platforms, Winch,
												Ropes, Sling , Wire, Forklift
											</li>
											<li>
												Trucks: Tipping truck, Sided-body, flat bed, Crane
												truck, tanker, box truck
											</li>
											<p className="pt-3">
												{" "}
												<span className="brand-style">
													<b>
														Gear, Flow, Centrifugal, Rotary, Hydraulic,
														submersible, Booster etc.
													</b>
												</span>
											</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="4" className="my-2">
										<Accordion.Header>
											{" "}
											<i className="fa-solid fa-compass fa-bounce"></i>{" "}
											&nbsp;&nbsp;&nbsp;<b> NAVIGATION & COMMUNICATION</b>
										</Accordion.Header>
										<Accordion.Body>
											<li>Sales</li>
											<li>Troubleshoot</li>
											<li>Installation</li>
											<li>Programming</li>
											<p className="pt-3">
												{" "}
												<span className="brand-style">
													<b>
														Radar, GMDSS, EPIRB, etc. (Sailor, Jotron, NAVTEX,
														Furuno, Samyung)
													</b>
												</span>
											</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="5" className="my-2">
										<Accordion.Header>
											{" "}
											<i className="fa-solid fa-shield fa-bounce"></i>{" "}
											&nbsp;&nbsp;&nbsp;<b> SAFETY SYSTEM</b>
										</Accordion.Header>
										<Accordion.Body>
											<li>Sales</li>
											<li>Installation</li>
											<li>Programming</li>
											<p className="pt-2">
												We offer the above for the following:
											</p>
											<li>Addressable & Conventional Fire Alarm</li>
											<li>Industrial Safety Tool</li>
											<li>Safety Equipment and Gadget</li>
											<li>Safety Protection Solutions</li>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="6" className="my-2">
										<Accordion.Header>
											{" "}
											<i className="fa-solid fa-credit-card fa-bounce"></i>{" "}
											&nbsp;&nbsp;&nbsp;<b> PROCUREMENT</b>
										</Accordion.Header>
										<Accordion.Body>
											<li>All Brands of Engine Spare Parts</li>
											<li>Engineering Equipment</li>
											<li>Outsource</li>
											<li>Shipment (Air & Sea)</li>
											<li>Freight Forwarding</li>
											<li>Warehouse and Terminal Storage</li>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
						</div>
					</div>
				</div>
			</div>

			<>
				<Footer />
			</>
		</div>
	);
}
