import React, { useRef } from "react";
import {
	motion,
	useScroll,
	useSpring,
	useTransform,
} from "framer-motion/dist/framer-motion";
import safetyPhoto from "../../images/Safety.jpg";
import teamworkPhoto from "../../images/teamwork.jpg";
import integrityPhoto from "../../images/integrity.jpg";


export default function companyValues() {
	return (
		<div className="">
			<div className="text-center pt-5 pb-4">
				<h2 className="pageTitle">Our Values</h2>
				<p><b>Providing full turn-key solutions</b> </p>
			</div>
            
			{/* <div className="row widthFix">
				<div className="col-sm-6">
					<div className="card border-danger-subtle">
						<div className="card-body">
							<h5 className="card-title">Special title treatment</h5>
							<p className="card-text">
								With supporting text below as a natural lead-in to additional
								content.
							</p>
						
						</div>
					</div>
				</div>
				<div className="col-sm-6">
					<div className="card">
						<div className="card-body">
							<h5 className="card-title">Special title treatment</h5>
							<p className="card-text">
								With supporting text below as a natural lead-in to additional
								content.
							</p>
						
						</div>
					</div>
				</div>
				<div className="col-sm-6">
					<div className="card">
						<div className="card-body">
							<h5 className="card-title">Special title treatment</h5>
							<p className="card-text">
								With supporting text below as a natural lead-in to additional
								content.
							</p>
							
						</div>
					</div>
				</div>
			</div> */}
			<div className="card-group p-4">
				<div className="card m-2 border-warning shadow-sm">
					<img src={integrityPhoto} height="250px;" className="card-img-top" alt="..." />
					<div className="card-body">
						<h5 className="card-title"><b>Integrity</b></h5>
						<p className="card-text cardGroupText">
                        We carry out our responsibilities with integrity,
                         dedication, and confidentiality. In our corporate culture,
                          we value loyalty, trustworthiness, and openness as essential 
                          personal characteristics.
						</p>
						
					</div>
				</div>
				<div className="card m-2 border-warning shadow-sm">
					<img src={teamworkPhoto} height="250px;" className="card-img-top" alt="..." />
					<div className="card-body">
						<h5 className="card-title"><b>Teamwork</b></h5>
						<p className="card-text cardGroupText">
                        We work as one cohesive team with mutual trust and respect 
                        to achieve our collective goals and foster team spirit through 
                        effective communication and caring for one another.
						</p>
					</div>
				</div>
				<div className="card m-2 border-warning shadow-sm">
					<img src={safetyPhoto} height="250px;" className="card-img-top" alt="..." />
					<div className="card-body">
						<h5 className="card-title"><b>Health & Safety</b></h5>
						<p className="card-text cardGroupText">
                        We are dedicated to fostering a healthy and 
                        safe working environment. We accept personal responsibility
                         for our own safety as well as accountability for our customers,
                          employees, contractors, and communities.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
