import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
	return (
		<div className="pt-4">
			{/* <div className="container my-5">
			<div className="container my-5"> */}
			{/* Footer */}
			<footer
				className="text-center text-lg-start text-white"
				style={{ backgroundColor: "#15818b" }}>
				{/* Section: Social media */}
				<section
					className="d-flex justify-content-between p-4"
					style={{ backgroundColor: "#1ba6b3" }}>
					{/* Left */}
					<div className="me-5">
						<span>Get connected with us on social networks:</span>
					</div>
					{/* Left */}
					{/* Right */}
					<div>
						{/* <Link to={"/"} className="text-white me-4">
							<i className="fab fa-facebook-f" />
						</Link>
						<Link to={"/"} className="text-white me-4">
							<i className="fab fa-twitter" />
						</Link>
						<Link to={"/"} className="text-white me-4">
							<i className="fab fa-instagram" />
						</Link> */}
						<Link to={"/"} className="text-white me-4">
							<i className="fab fa-whatsapp" />
						</Link>
					
					</div>
				</section>
				<section className>
					<div className="container text-center text-md-start mt-5">
						{/* Grid row */}
						<div className="row mt-3">
							{/* Grid column */}
							<div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
								{/* Content */}
								<h6 className="text-uppercase fw-bold">Swift Fix Services</h6>
								<hr
									className="mb-4 mt-0 d-inline-block mx-auto"
									style={{
										width: "60px",
										backgroundColor: "#7c4dff",
										height: "2px",
									}}
								/>
								<p>
									We are a leading engineering procurement and consultancy
									company.
								</p>
							</div>
							{/* Grid column */}
							{/* Grid column */}
							<div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
								{/* Links */}
								<h6 className="text-uppercase fw-bold">About Us</h6>
								<hr
									className="mb-4 mt-0 d-inline-block mx-auto"
									style={{
										width: "60px",
										backgroundColor: "#7c4dff",
										height: "2px",
									}}
								/>
								<p>
									<Link to="/about-us" className="footer-items  text-white">
										Customer Satisfaction
									</Link>
								</p>
								<p>
									<Link to="/about-us" className="footer-items text-white">
										Community Responsibility
									</Link>
								</p>
								<p>
									<Link to="/about-us" className="footer-items text-white">
										Social Oriented
									</Link>
									<Link to="/about-us"  className="footer-items text-white">
										Integrity
									</Link>
								</p>
							</div>
							{/* Grid column */}
							{/* Grid column */}
							<div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
								{/* Links */}
								<h6 className="text-uppercase fw-bold">Our Services</h6>
								<hr
									className="mb-4 mt-0 d-inline-block mx-auto"
									style={{
										width: "60px",
										backgroundColor: "#7c4dff",
										height: "2px",
									}}
								/>
								<p>
									<Link to="/our-services" className="footer-items  text-white">
										Engineering
									</Link>
								</p>
								<p>
									<Link to="/our-services" className="footer-items text-white">
										Procurement
									</Link>
								</p>
								<p>
									<Link to="/our-services" className="footer-items text-white">
										Consultancy
									</Link>
								</p>
								
							</div>
							{/* Grid column */}
							{/* Grid column */}
							<div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
								{/* Links */}
								<h6 className="text-uppercase fw-bold">Contact US</h6>
								<hr
									className="mb-4 mt-0 d-inline-block mx-auto"
									style={{
										width: "60px",
										backgroundColor: "#7c4dff",
										height: "2px",
									}}
								/>
								<p>
									<i className="fas fa-home mr-3" /> 24A, Awkuzu Street, Lekki
									phase 1, Lagos
								</p>
								<p>
									<i className="fas fa-envelope mr-3" />{" "}
									info@swiftfixservicesltd.com
								</p>
								<p>
									<i className="fas fa-phone mr-3" />
									+234 813 259 5801
								</p>
							</div>
							{/* Grid column */}
						</div>
						{/* Grid row */}
					</div>
				</section>
				{/* Section: Links  */}
				{/* Copyright */}
				<div
					className="text-center p-3"
					style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
					© 2023 Copyright:
					<Link className="text-white" to="/">
						{" "}
						Swift Fix Services
					</Link>
				</div>
				{/* Copyright */}
			</footer>
			{/* Footer */}
			{/* </div>

			</div> */}
		</div>
	);
}
